import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from 'components/SEO'
import { css } from '@emotion/core'
import Container from 'components/Container'
import Layout from '../components/Layout'
import Share from '../components/Share'
import config from '../../config/website'
import { bpMaxSM } from '../lib/breakpoints'
import Hero from 'components/Hero'
import styled from '@emotion/styled'

const Spacer = styled.div`
  margin-bottom: 100px;
`

export default function Post({
  data: { site, mdx },
  pageContext: { next, prev },
}) {
  const author = mdx.frontmatter.author || config.author
  const tags = mdx.frontmatter.tags
  const garden = mdx.frontmatter.garden
  const date = mdx.frontmatter.date
  const edited = mdx.frontmatter.edited
  const title = mdx.frontmatter.title
  const banner = mdx.frontmatter.banner
  const time = mdx.fields.readingTime.text
  const words = mdx.fields.readingTime.words
  const img = mdx.frontmatter.img
  const color = mdx.frontmatter.color

  const sub = `Posted by ${author}`
  const sub2 = `Created: ${date} | Last Edited: ${edited} | ${time} | ${words} words`
  // const img = `bg.jpg`

  return (
    <Layout site={site} frontmatter={mdx.frontmatter}>
      <SEO frontmatter={mdx.frontmatter} isBlogPost />
      <Hero
        heading={title}
        subHeading={sub}
        subHeading2={sub2}
        blog={true}
        avatar={false}
        post={img}
        tags={tags}
        garden={garden}
        color={color}
      />
      <Spacer />
      <article
        css={css`
          width: 100%;
          display: flex;
        `}
      >
        <Container>
          {banner && (
            <div
              css={css`
                padding: 30px;
                ${bpMaxSM} {
                  padding: 0;
                }
              `}
            >
              <Img
                sizes={banner.childImageSharp.fluid}
                alt={site.siteMetadata.keywords.join(', ')}
              />
            </div>
          )}
          <br />
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </Container>
      </article>
      <Container noVerticalPadding>
        <Share
          url={`${config.siteUrl}/${mdx.frontmatter.slug}/`}
          title={title}
          twitterHandle={config.twitterHandle}
        />
        <br />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...site
    }
    mdx(fields: { id: { eq: $id } }) {
      frontmatter {
        title
        tags
        garden
        img
        color
        date(formatString: "MMMM DD, YYYY")
        edited(formatString: "MMMM DD, YYYY")
        author
        banner {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        slug
        keywords
      }
      fields {
        readingTime {
          text
          words
        }
      }
      body
    }
  }
`
